// src/app/auth/role-guard.service.ts
import { Injectable } from '@angular/core';
import {CanActivate } from '@angular/router';
import { Cookie } from 'ng2-cookies/ng2-cookies';

@Injectable()

export class RoleGuardService implements CanActivate {
  constructor() {}

  canActivate(): boolean {

    const token = Cookie.get('token');
    if (!token) {
      return false;
    }
    else{
      return true;
    }

  }
}
